import { Loading } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { specialWeekMutation } from "@/services/queries";
import { formatDefault } from "@/utils/dates";
import { useState } from "react";

type SpecialWeekProps = {
  alreadyChose?: boolean;
  nextDeliveryDate?: string;
  selectedDeliveryDate: string | null;
};

const SpecialWeek = ({
  alreadyChose,
  selectedDeliveryDate,
}: SpecialWeekProps) => {
  const [newDeliveryDate, setNewDeliveryDate] = useState<string>();
  const mutation = specialWeekMutation();

  const handleOptionSelection = () => {
    if (newDeliveryDate) {
      // @ts-expect-error
      mutation.mutate(newDeliveryDate);
    }
  };

  if (mutation.isPending) {
    return (
      <div className="border-4 rounded-md border-red-500 bg-red-500/5 p-4">
        <Loading message="Guardando fecha..." />
      </div>
    );
  }

  if (mutation.isSuccess || alreadyChose) {
    return (
      <div className="border-4 rounded-md border-red-500 bg-red-500/5 p-4">
        {selectedDeliveryDate ? (
          <p className="text-sm">
            🇨🇱 El envío que tenias programado para la semana del 18 quedó
            asignado para el dia{" "}
            <strong className="">{formatDefault(selectedDeliveryDate)}</strong>.
          </p>
        ) : (
          <p className="text-sm">
            🇨🇱 Hemos guardado tu preferencia para el envío de la semana del 18.
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="border-4 rounded-md border-red-500 bg-red-500/5 p-4">
      <h3 className="font-semibold">🇨🇱 Información</h3>
      <p className="text-muted-foreground text-sm mb-2">
        Tienes un pedido programado para la <strong>semana del 18</strong>. Esa
        semana debido a los feriados no operaremos normalmente, por lo que te
        ofrecemos algunas alternativas de fechas para reprogramar ese pedido:
      </p>
      <p className="text-muted-foreground text-sm mb-2">
        ⚠️ Si escoges el <strong>lunes 16</strong>, no podrás editar tu caja.
      </p>
      <p className="text-muted-foreground text-sm mb-4">
        ⚠️ Si no escoges una fecha vamos a <strong>posponer el envío</strong>{" "}
        para la semana siguiente.
      </p>
      <div className="flex gap-2">
        <div className="bg-white w-full">
          <Select
            value={newDeliveryDate}
            onValueChange={(v) => setNewDeliveryDate(v)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Selecciona una fecha" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="MONDAY">Lunes 16 de septiembre</SelectItem>
              <SelectItem value="TUESDAY">Martes 17 de septiembre</SelectItem>
              <SelectItem value="POSTPONE">Posponer una semana</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Button
          onClick={handleOptionSelection}
          disabled={newDeliveryDate === undefined}
        >
          Confirmar
        </Button>
      </div>
      {mutation.isError && (
        <p className="text-sm text-red-500 font-semibold mt-4">
          💥 Hubo un error inesperado. Por favor intenta de nuevo.
        </p>
      )}
    </div>
  );
};

export default SpecialWeek;
