import { Separator } from "@/components/ui/separator";
import {
  userHomeQuery,
  userSubscriptionDataQuery,
  specialWeekQuery,
} from "@/services/queries";
import {
  RetryErrorMessage,
  Loading,
  Stats,
  SubscriptionDetails,
  SubscriptionShipping,
  ButtonCustomLink,
} from "@/components";
import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import SubscriptionStatus from "@/serverComponents/SubscriptionStatus/SubscriptionStatus";
import ReactivationWizard from "@/serverComponents/ReactivationWizard";
import boxBig from "@/assets/photos/50/big.webp";
import { useState } from "react";
import SpecialWeek from "./SpecialWeek";

type UserSubscriptionProps = {
  showHeader?: boolean;
  userName?: string;
  onLogout?: () => void;
};

const UserSubscription = ({
  showHeader = true,
  userName,
  onLogout,
}: UserSubscriptionProps) => {
  const {
    data: response,
    isLoading,
    refetch,
    isError,
    error,
  } = userHomeQuery();
  const {
    data: userSubscriptionDataResponse,
    isLoading: userSubscriptionDataIsLoading,
  } = userSubscriptionDataQuery();
  const { data: specialWeekResponse } = specialWeekQuery();
  const [showReactivationWizard, setShowReactivationWizard] = useState(false);

  const renderContent = () => {
    const data = response?.data;
    const userSubscriptionData = userSubscriptionDataResponse?.data;
    const userHasDeliveryOnSpecialWeek =
      specialWeekResponse?.data.has_delivery_on_special_week;
    const userAlreadyChoseSpecialWeek =
      specialWeekResponse?.data.user_already_chose;

    if (isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información de tu suscripción"}
          message={error.message}
          retry={() => refetch()}
          error={error}
        />
      );
    }

    if (isLoading || userSubscriptionDataIsLoading) {
      return <Loading message="🔍 Buscando tu información..." />;
    }

    if (data?.show_resume_button) {
      return (
        <div className="px-4 py-6 border rounded-md border-primary flex flex-col justify-center items-center space-y-4 bg-primary/10">
          <p className="text-base">
            {userName ? (
              <span>
                Hola{" "}
                <strong className="text-primary font-semibold">
                  {userName}
                </strong>
                ,{" "}
              </span>
            ) : (
              ""
            )}
            completa tu registro para acceder a tu suscripción
          </p>
          <div className="flex flex-col space-y-2">
            {data.corrupted_account ? (
              <Button>
                <Link to="/register/subscriptions">Completar registro</Link>
              </Button>
            ) : (
              <ButtonCustomLink to="/rcheckout" search={{ uid: data.username }}>
                Completar registro
              </ButtonCustomLink>
            )}
            {onLogout && (
              <Button variant="link" onClick={onLogout}>
                Cerrar sesión
              </Button>
            )}
          </div>
        </div>
      );
    }

    if (data && userSubscriptionData) {
      const {
        show_stats: showStats,
        stats_co2: statsCO2,
        stats_h2o: statsH2O,
        stats_kg: statsKg,
      } = data;

      const {
        user_subscription_name: subcriptionName,
        user_subscription_frecuency: subscriptionFrecuency,
        user_subcription_delivery_day: deliveryDay,
        user_subscription_type: subscriptionType,
        user_delivery_address: deliveryAddress,
        user_delivery_district: deliveryDistrict,
        user_next_delivery_date: nextDelivery,
        user_pickup_name: pickupName,
        user_pickup_address: pickupAddress,
        user_pickup_district: pickupDistric,
      } = userSubscriptionData;

      const subscriptionIsTerminated =
        data.subscription_status === "TERMINATED";

      if (subscriptionIsTerminated) {
        return (
          <div className="border rounded-md p-4">
            <div className="flex gap-2 border-b pb-2 mb-2">
              <div>
                <p className="text-sm font-semibold">
                  ⚠️ Suscripción desactivada
                </p>
                <p className="text-sm text-muted-foreground">
                  Mientras tu suscripción esté desactivada no se va a generar
                  ningún cobro
                </p>
              </div>
            </div>
            <div className="flex gap-8 py-4 items-center">
              <div className="max-w-[200px] hidden sm:block">
                <img src={boxBig} alt="Caja de Maifud" />
              </div>
              <div>
                <p className="text-lg font-semibold text-primary">
                  Reactiva tu suscripción
                </p>
                <div className="max-w-[150px] mx-auto py-2 block sm:hidden">
                  <img src={boxBig} alt="Caja de Maifud" />
                </div>
                <ul className="text-xs list-disc pl-4 py-2">
                  <li>Personaliza el contenido tu caja</li>
                  <li>
                    Cambia la frecuencia y el tipo de caja según lo que
                    necesites
                  </li>
                  <li>Complementa tu caja en la misma plataforma</li>
                  <li>Pausa cuando quieras</li>
                </ul>
                <div className="flex justify-end py-2">
                  <Button
                    className="w-full"
                    onClick={() => setShowReactivationWizard(true)}
                  >
                    Reactivar suscripción
                  </Button>
                </div>
              </div>
            </div>
            {showReactivationWizard && (
              <ReactivationWizard
                onClose={() => setShowReactivationWizard(false)}
              />
            )}
          </div>
        );
      }

      return (
        <div className="flex flex-col space-y-6">
          {userHasDeliveryOnSpecialWeek && (
            <SpecialWeek
              alreadyChose={userAlreadyChoseSpecialWeek}
              nextDeliveryDate={specialWeekResponse?.data.next_delivery_date}
              selectedDeliveryDate={
                specialWeekResponse.data.selected_delivery_date
              }
            />
          )}
          <SubscriptionStatus />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div>
              <SubscriptionDetails
                name={subcriptionName}
                frecuency={subscriptionFrecuency}
                deliveryDay={deliveryDay}
                nextDelivery={nextDelivery}
              />
            </div>
            <div>
              <SubscriptionShipping
                type={subscriptionType}
                deliveryAddress={deliveryAddress}
                deliveryDistrict={deliveryDistrict}
                pickupAddress={pickupAddress}
                pickupName={pickupName}
                pickupDistrict={pickupDistric}
              />
            </div>
          </div>
          <div>
            {showStats && <Stats stats={{ statsCO2, statsH2O, statsKg }} />}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="space-y-4">
      {/* showHeader is false when a new user didn´t complete it registration process */}
      {showHeader && (
        <>
          <div>
            <h3 className="text-lg font-medium">Suscripción</h3>
            <p className="text-sm text-muted-foreground">
              Detalles de tu suscripción y próximos envíos.
            </p>
          </div>
          <Separator />
        </>
      )}
      <div className="flex flex-col space-y-2">{renderContent()}</div>
    </div>
  );
};

export default UserSubscription;
